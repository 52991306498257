import React from 'react'
import PropTypes from 'prop-types'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import * as CONSTANTS from '../../Constants';

const testimonials = props => {
    return (
      <section class="ftco-section testimony-section slash">
                <div class="container">
                    <div class="row justify-content-center pb-3">
                    <div class="col-md-7 text-center heading-section heading-section-white ftco-animate">
                        <h1 class="py-4">Happy Customers Says</h1>
                    </div>
                    </div>
                    <div class="row ftco-animate justify-content-center">
                    <div class="col-md-12">
                    <div style={{ margin: '15px', position: 'relative' }}>
                        <Carousel 
                          swipeable={true}
                          draggable={true}
                          showDots={false}
                          ssr={true} // means to render carousel on server-side.
                          infinite={true}
                          autoPlay={true}
                          autoPlaySpeed={1000}
                          keyBoardControl={true}
                          customTransition="transform 300ms ease-in-out"
                          transitionDuration={3000}
                          containerClass="carousel-container margin-15"
                          removeArrowOnDeviceType={[ "tablet", "mobile"]}
                          dotListClass="custom-dot-list-style"
                          itemClass="carousel-item-padding-40-px"
                          responsive={{
                              desktop: {
                                breakpoint: {
                                  max: 3000,
                                  min: 1024
                                },
                                items: 2,
                                partialVisibilityGutter: 40
                              },
                              mobile: {
                                breakpoint: {
                                  max: 464,
                                  min: 0
                                },
                                items: 1,
                                partialVisibilityGutter: 30
                              },
                              tablet: {
                                breakpoint: {
                                  max: 1024,
                                  min: 464
                                },
                                items: 3,
                                partialVisibilityGutter: 30
                              }
                            }}>
                          <div class="post-slide">
                            <div class="post-header">
                              <center>
                              <ul class="post-bar">
                                <li><img src="/assets/images/ritesh.jpeg" alt=""/><a href="#" style={{fontSize : '20px', fontWeight: 600}}>Mr. Ritesh Chordia</a></li>
                                {/* <li><i class="fa fa-calendar"></i>02 June 2018</li> */}
                              </ul>
                              </center>
                            </div>
                            <div class="pic">
                              <center>
                                <ul class="post-category">
                                  <li><a href="#">Director, </a></li>
                                  <li><a href="#">&nbsp;Bioprex Labs. (Herbal Product Mfg. Company)</a></li>
                                </ul>
                              </center>
                            </div>
                            <p class="post-description">
                            Thanks a lot to "{CONSTANTS.PROJECT_TITLE}" who has provided us total consultancy for setting up our Facility and Documentation management as per WHO GMP guidelines along with trainings. We have hence successfully faced Audit of Our Important customer from South America.
Thus, opening us the Latin American Market. Really done a great job!!! Keep it Up!!!
                            </p>
                          </div>
                          <div class="post-slide">
                            <div class="post-header">
                              <center>
                              <ul class="post-bar">
                                <li><img src="/assets/images/ajay.png" alt=""/><a href="#" style={{fontSize : '20px', fontWeight: 600}}>Mr. Ajay Saxena</a></li>
                                {/* <li><i class="fa fa-calendar"></i>05 June 2018</li> */}
                              </ul>
                              </center>
                            </div>
                            <div class="pic">
                              <center>
                                <ul class="post-category">
                                  <li><a href="#">Director, </a></li>
                                  <li><a href="#">&nbsp;Rusan Pharma Ltd.</a></li>
                                </ul>
                              </center>
                            </div>
                            <p class="post-description">
                            {CONSTANTS.PROJECT_TITLE} had undertaken audit work of our unit in Ankleshwar facility and they have performed exceedingly well and we are quite happy with the input provided by them to face our HEALTH CANADA audit successfully. So, really Thank you very much for their great support and commitment!!!
                            </p>
                          </div>
                          
                          <div class="post-slide">
                            <div class="post-header">
                              <center>
                              <ul class="post-bar">
                                <li><img src="/assets/images/sandip.png" alt=""/><a href="#" style={{fontSize : '20px', fontWeight: 600}}>Mr. Sandeep Deshmukh</a></li>
                                {/* <li><i class="fa fa-calendar"></i>05 June 2018</li> */}
                              </ul>
                              </center>
                            </div>
                            <div class="pic">
                              <center>
                                <ul class="post-category">
                                  <li><a href="#">Director, </a></li>
                                  <li><a href="#">&nbsp;Delta Finochem</a></li>
                                </ul>
                              </center>
                            </div>
                            <p class="post-description">
                            {CONSTANTS.PROJECT_TITLE} is ultimate rock stars! Which is highly professional, educated, experienced consultancy, dedicated to helping their clients to achieve a goals of success. I would like to express gratitude on behalf of all of us at Delta Finochem for outstanding contribution made by {CONSTANTS.PROJECT_TITLE} to the establishment of our company's Quality Management Systems. Keep up great work. Thanks! 
                            </p>
                          </div>
                          
                        </Carousel>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
    )
}

testimonials.propTypes = {

}

export default testimonials
