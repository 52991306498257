import React from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";

import Top from '../../components/common/top';
import Header from '../../components/common/header';
import Footer from '../../components/common/footer';

const services = props => {
    return (
        <div>
            <Top/>
            <Header/>
            <div class="full-title">
                <div class="container">
                    <h1 class="mt-4 mb-3"> Services</h1>
                    <div class="breadcrumb-main">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                        <Link to="/" href="index.html">Home</Link>
                        </li>
                        <li class="breadcrumb-item active"> Services </li>
                    </ol>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="about-main">
                    <div class="row">
                    <div class="col-lg-6">
                        <img class="img-fluid rounded mb-4" src="assets/images/about-img.jpg" alt="" />
                    </div>
                    <div class="col-lg-6">
                        <h2>Services</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sed voluptate nihil eum consectetur similique? Consectetur, quod, incidunt, harum nisi dolores delectus reprehenderit voluptatem perferendis dicta dolorem non blanditiis ex fugiat.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Saepe, magni, aperiam vitae illum voluptatum aut sequi impedit non velit ab ea pariatur sint quidem corporis eveniet. Odit, temporibus reprehenderit dolorum!</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et, consequuntur, modi mollitia corporis ipsa voluptate corrupti eum ratione ex ea praesentium quibusdam? Aut, in eum facere corrupti necessitatibus perspiciatis quis?</p>
                    </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    )
}

services.propTypes = {

}

export default services
