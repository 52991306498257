import React from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";

import Top from '../common/top';
import Header from '../common/header';
import Footer from '../common/footer';
import Customers from '../home/customers';
import Contact from '../home/contact_us';

import * as CONSTANTS from '../../Constants';

const training = props => {
    return (
      <div>
        <Top/>
        <Header/>
        <div class="full-title">
          <div class="container">
            <h1 class="mt-4 mb-3">Services - Training</h1>
            <div class="breadcrumb-main">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/" href="index.html">Home</Link>
                </li>
                <li class="breadcrumb-item active"> Services </li>
                <li class="breadcrumb-item active"> Training </li>
              </ol>
            </div>
          </div>
        </div>

          <div class="container">
          <div class="about-main">
            <div class="row">
              <div class="col-lg-6">
                <img class="img-fluid rounded mb-4" src="assets/images/services-img-03.jpg" alt="" />
              </div>
              <div class="col-lg-6">
                <h3 className="mb-3">Training</h3>
                <ul>
                    <li>GMP/GLP/GDP System related to API & Formulations</li>
                    <li>Auditor's Qualification</li>
                    <li>Site Inspection Prepareness</li>      
                    <li>EUDRALEX Vol.4/ICH/21CFR Part 210 & 211/Other Relevant GMP Guidelines</li>
                    <li>Data Integrity & Ethics</li>
                    <li>Educational Training to Institutes</li>
                    <li>Training based on customers requirement</li>
                  </ul>
              </div>
            </div>
          </div>
        </div>

        <Customers />
        
        <Contact />
        <Footer/>
      </div>
    )
}

training.propTypes = {

}

export default training
