import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {Link} from "react-router-dom";

let current_path = document.location.pathname;

const Header = props => {

    let currentLocation = document.location.pathname;

    const [current_path] = useState(currentLocation);

    return (
        <nav class="navbar navbar-expand-lg navbar-dark bg-light top-nav">
                <div class="container">
                    <Link className={'navbar-brand'} to="/">
                        <img src="/assets/images/logo2.png" alt="logo" />
                            </Link>
                            <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="fas fa-bars"></span>
                            </button>
                            <div class="collapse navbar-collapse" id="navbarResponsive">
                        <ul class="navbar-nav ml-auto">
                          <li class="nav-item">
                            <Link className={'nav-link '+(current_path === '/' ? 'active' : '')} to="/">Home </Link>
                          </li>
                          <li class="nav-item">
                            <Link className={'nav-link '+(current_path === '/about' ? 'active' : '')} to="/about">About Us </Link>
                          </li>
                          <li class="nav-item dropdown">
                            <Link className={'nav-link '+((current_path === '/tpqa' || current_path === '/gmp' || current_path === '/training' || current_path === '/csv') ? 'active' : '')} to="#"  id="navbarDropdownBlog" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Services <i class="fas fa-sort-down"></i> 
                            </Link>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownBlog">
                              <Link className={'dropdown-item '+(current_path === '/tpqa' ? 'active' : '')} to="/tpqa">Third Party Quality Audit (TPQA) </Link>
                              <Link className={'dropdown-item '+(current_path === '/gmp' ? 'active' : '')} to="/gmp">GMP Support </Link>
                              <Link className={'dropdown-item '+(current_path === '/training' ? 'active' : '')} to="/training">Training </Link>
                              <Link className={'dropdown-item '+(current_path === '/csv' ? 'active' : '')} to="/csv">Computer System Validation (CSV) </Link>
                            </div>
                          </li>
                          {/* <li class="nav-item">
                            <Link className={'nav-link '+(current_path === '/marketing' ? 'active' : '')} to="/marketing">Marketing </Link>
                          </li>
                          <li class="nav-item">
                            <Link className={'nav-link '+(current_path === '/consultation' ? 'active' : '')} to="/consultation">Consultation </Link>
                          </li>
                          <li class="nav-item">
                            <Link className={'nav-link '+(current_path === '/training' ? 'active' : '')} to="/training">Training </Link>
                          </li>
                          <li class="nav-item">
                            <Link className={'nav-link '+(current_path === '/gallery' ? 'active' : '')} to="/gallery">Gallery </Link>
                          </li>
                          <li class="nav-item">
                            <Link className={'nav-link '+(current_path === '/news' ? 'active' : '')} to="/news">News </Link>
                          </li>
                          <li class="nav-item dropdown">
                            <a class="nav-link" href="#" id="navbarDropdownBlog" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Pages <i class="fas fa-sort-down"></i></a>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownBlog">
                              <a class="dropdown-item" href="faq.html">FAQ</a>
                              <a class="dropdown-item" href="404.html">404</a>
                              <a class="dropdown-item" href="pricing.html">Pricing Table</a>
                            </div>
                          </li>
                          <li class="nav-item dropdown">
                            <a class="nav-link" href="#" id="navbarDropdownPortfolio" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Portfolio <i class="fas fa-sort-down"></i></a>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownPortfolio">
                              <a class="dropdown-item" href="portfolio-3-col.html">3 Column Portfolio</a>
                              <a class="dropdown-item" href="portfolio-4-col.html">4 Column Portfolio</a>
                              <a class="dropdown-item" href="portfolio-item.html">Single Portfolio Item</a>
                            </div>
                          </li>
                          <li class="nav-item dropdown">
                            <a class="nav-link" href="#" id="navbarDropdownBlog" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Blog <i class="fas fa-sort-down"></i></a>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownBlog">
                              <a class="dropdown-item" href="blog.html">Blog</a>
                              <a class="dropdown-item" href="blog-post.html">Blog Post</a>
                            </div>
                          </li> */}
                          <li class="nav-item">
                            <Link className={'nav-link '+(current_path === '/contact_us' ? 'active' : '')} to="/contact_us">Meet Us </Link>
                          </li>
                        </ul>
                    </div>
                </div>
            </nav>
            
    )
}

Header.propTypes = {

}

export default Header
