import React from 'react'
import PropTypes from 'prop-types'

import * as CONSTANTS from '../../Constants';

const welcome = props => {
    return (
      <div class="container">        
          {/* <div class="about-main">
              <div class="row">
                <div class="col-lg-6">
                    <h2>Welcome to {CONSTANTS.PROJECT_TITLE}</h2>
                    <p>{CONSTANTS.PROJECT_TITLE} is an innovative and advanced consulting organization providing exceptional quality and regulatory compliance services globally. {CONSTANTS.PROJECT_TITLE} GMP Consultancy associates with you for services related to GMP Audits, Quality Assurance, Compliance, CSV, Procedure/System Optimization & Development and Training. {CONSTANTS.PROJECT_TITLE} Consultancy is a GMP consulting organization intended to support in various current regulatory requirements, perform quality audits to access the gaps, suggest solutions to meet the regulatory requirements and develop the quality systems.</p>
                </div>
                <div class="col-lg-6">
                    <img class="img-fluid rounded" src="assets/images/about-img.jpg" alt="" />
                </div>
              </div>
          </div> */}

            <div class="slider-2">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="">
                                <img class="img-fluid" src="assets/images/welcome.jpg" alt="alternative"/>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <h2>Welcome to <br></br>{CONSTANTS.PROJECT_TITLE}</h2>
                            <p style={{fontWeight: 600}}>{CONSTANTS.PROJECT_TITLE} is an innovative and advanced consulting organization providing exceptional quality and regulatory compliance services globally. {CONSTANTS.PROJECT_TITLE} GMP Consultancy associates with you for services related to GMP Audits, Quality Assurance, Compliance, CSV, Procedure/System Optimization & Development and Training. {CONSTANTS.PROJECT_TITLE} Consultancy is a GMP consulting organization intended to support in various current regulatory requirements, perform quality audits to access the gaps, suggest solutions to meet the regulatory requirements and develop the quality systems.</p>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    )
}

welcome.propTypes = {

}

export default welcome
