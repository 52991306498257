import React from 'react'
import {Link} from "react-router-dom";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

import Top from '../common/top';
import Header from '../common/header';
import Footer from '../common/footer';

import * as CONSTANTS from '../../Constants';

class contact_us extends React.Component {

    constructor(props) {
        super(props);
        this.state = { full_name: '', job_title: '', company_name : '', phone: '', email: '', country : '', message: ''};
    }

    createNotification = (type, message, title) => {
        return () => {
          switch (type) {
            case 'info':
              NotificationManager.info(message, title, 3000);
              break;
            case 'success':
              NotificationManager.success(message, title, 3000);
              break;
            case 'warning':
              NotificationManager.warning(message, title, 3000);
              break;
            case 'error':
              NotificationManager.error('Error message', 'Click me!', 5000, () => {
                alert('callback');
              });
              break;
          }
        };
    };

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }
    
    handleSubmit = (event) => {
        //NotificationManager.success('Your messsage recieved successfully. We will contact you soon.123', 'Thank you.');
        console.log(this.state);
        fetch(CONSTANTS.SERVER_BASE_URL+'contact/contact_us.php', {
            method: 'POST',
            // We convert the React state to JSON and send it as the POST body
            //body: JSON.stringify(this.state),
            body: Object.keys(this.state).map(key => key + '=' + this.state[key]).join('&'),
            headers:{ 'Content-Type': 'application/x-www-form-urlencoded' }
          }).then(function(response) {
            console.log(response);                
            return response.json();
          })
          .then((data) => { console.log('This is your data', data);
          if(data.status === 'success')
          {
                this.form.reset();
              NotificationManager.success('Your messsage recieved successfully. We will contact you soon.', 'Thank you.', 5000);
          }
          else
              NotificationManager.error('Something went wrong. Please try again.', 'Error', 5000);});
    
        event.preventDefault();
    }    
    
    render()
    {
        return (
            <div>
                <Top/>
                <Header/>
                <div class="full-title">
                    <div class="container">
                        <h1 class="mt-4 mb-3"> Meet Us</h1>
                        <div class="breadcrumb-main">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                            <Link to="/" href="index.html">Home</Link>
                            </li>
                            <li class="breadcrumb-item active"> Meet Us </li>
                        </ol>
                        </div>
                    </div>
                </div>

                <div class="contact-main">
                    <div class="container">
                    <div class="row">
                            <div class="col-lg-8 mb-4 contact-left">
                                <h3>Send us a Message</h3>
                                <form name="sentMessage" id="contactForm" novalidate onSubmit={this.handleSubmit} ref={form => this.form = form}>
                                    <div class="control-group form-group">
                                        <div class="controls">
                                            <input type="text" placeholder="Full Name" class="form-control" name="full_name" id="name" required data-validation-required-message="Please enter your name." onChange={this.handleChange}  />
                                            <p class="help-block"></p>
                                        </div>
                                    </div>

                                    <div class="control-group form-group">
                                        <div class="controls">
                                            <input type="text" placeholder="Job Title" class="form-control" name="job_title" id="job_title" required data-validation-required-message="Please enter your name." onChange={this.handleChange}  />
                                            <p class="help-block"></p>
                                        </div>
                                    </div>
                                    <div class="control-group form-group">
                                        <div class="controls">
                                            <input type="text" placeholder="Company Name" class="form-control" name="company_name" id="company_name" required data-validation-required-message="Please enter your name." onChange={this.handleChange}  />
                                            <p class="help-block"></p>
                                        </div>
                                    </div>
                                    <div class="control-group form-group">
                                        <div class="controls">
                                            <input type="number" pattern=".{10,10}" minlength = "10" maxlength = "10" placeholder="Mobile" class="form-control" name="phone" id="phone" required data-validation-required-message="Please enter your phone number." onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div class="control-group form-group">
                                        <div class="controls">
                                            <input type="email" placeholder="Email Address" class="form-control" name="email" id="email" required data-validation-required-message="Please enter your email address." onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div class="control-group form-group">
                                        <div class="controls">
                                            {/* <input type="text" placeholder="Country" class="form-control" name="country" id="country" required data-validation-required-message="Please enter your name." onChange={this.handleChange}  /> */}
                                            <select name="country"  id="country"  class="form-control "  onChange={this.handleChange}  >
<option value="" selected="selected">Select Country</option>
<option value="Afghanistan">Afghanistan</option>
<option value="Albania">Albania</option>
<option value="Algeria">Algeria</option>
<option value="American Samoa">American Samoa</option>
<option value="Andorra">Andorra</option>
<option value="Angola">Angola</option>
<option value="Anguilla">Anguilla</option>
<option value="Antarctica">Antarctica</option>
<option value="Antigua And Barbuda">Antigua And Barbuda</option>
<option value="Argentina">Argentina</option>
<option value="Armenia">Armenia</option>
<option value="Aruba">Aruba</option>
<option value="Australia">Australia</option>
<option value="Austria">Austria</option>
<option value="Azerbaijan">Azerbaijan</option>
<option value="Bahamas The">Bahamas The</option>
<option value="Bahrain">Bahrain</option>
<option value="Bangladesh">Bangladesh</option>
<option value="Barbados">Barbados</option>
<option value="Belarus">Belarus</option>
<option value="Belgium">Belgium</option>
<option value="Belize">Belize</option>
<option value="Benin">Benin</option>
<option value="Bermuda">Bermuda</option>
<option value="Bhutan">Bhutan</option>
<option value="Bolivia">Bolivia</option>
<option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
<option value="Botswana">Botswana</option>
<option value="Bouvet Island">Bouvet Island</option>
<option value="Brazil">Brazil</option>
<option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
<option value="Brunei">Brunei</option>
<option value="Bulgaria">Bulgaria</option>
<option value="Burkina Faso">Burkina Faso</option>
<option value="Burundi">Burundi</option>
<option value="Cambodia">Cambodia</option>
<option value="Cameroon">Cameroon</option>
<option value="Canada">Canada</option>
<option value="Cape Verde">Cape Verde</option>
<option value="Cayman Islands">Cayman Islands</option>
<option value="Central African Republic">Central African Republic</option>
<option value="Chad">Chad</option>
<option value="Chile">Chile</option>
<option value="China">China</option>
<option value="Christmas Island">Christmas Island</option>
<option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
<option value="Colombia">Colombia</option>
<option value="Comoros">Comoros</option>
<option value="Republic Of The Congo">Republic Of The Congo</option>
<option value="Democratic Republic Of The Congo">Democratic Republic Of The Congo</option>
<option value="Cook Islands">Cook Islands</option>
<option value="Costa Rica">Costa Rica</option>
<option value="Cote D'Ivoire (Ivory Coast)">Cote D'Ivoire (Ivory Coast)</option>
<option value="Croatia (Hrvatska)">Croatia (Hrvatska)</option>
<option value="Cuba">Cuba</option>
<option value="Cyprus">Cyprus</option>
<option value="Czech Republic">Czech Republic</option>
<option value="Denmark">Denmark</option>
<option value="Djibouti">Djibouti</option>
<option value="Dominica">Dominica</option>
<option value="Dominican Republic">Dominican Republic</option>
<option value="East Timor">East Timor</option>
<option value="Ecuador">Ecuador</option>
<option value="Egypt">Egypt</option>
<option value="El Salvador">El Salvador</option>
<option value="Equatorial Guinea">Equatorial Guinea</option>
<option value="Eritrea">Eritrea</option>
<option value="Estonia">Estonia</option>
<option value="Ethiopia">Ethiopia</option>
<option value="External Territories of Australia">External Territories of Australia</option>
<option value="Falkland Islands">Falkland Islands</option>
<option value="Faroe Islands">Faroe Islands</option>
<option value="Fiji Islands">Fiji Islands</option>
<option value="Finland">Finland</option>
<option value="France">France</option>
<option value="French Guiana">French Guiana</option>
<option value="French Polynesia">French Polynesia</option>
<option value="French Southern Territories">French Southern Territories</option>
<option value="Gabon">Gabon</option>
<option value="Gambia The">Gambia The</option>
<option value="Georgia">Georgia</option>
<option value="Germany">Germany</option>
<option value="Ghana">Ghana</option>
<option value="Gibraltar">Gibraltar</option>
<option value="Greece">Greece</option>
<option value="Greenland">Greenland</option>
<option value="Grenada">Grenada</option>
<option value="Guadeloupe">Guadeloupe</option>
<option value="Guam">Guam</option>
<option value="Guatemala">Guatemala</option>
<option value="Guernsey and Alderney">Guernsey and Alderney</option>
<option value="Guinea">Guinea</option>
<option value="Guinea-Bissau">Guinea-Bissau</option>
<option value="Guyana">Guyana</option>
<option value="Haiti">Haiti</option>
<option value="Heard and McDonald Islands">Heard and McDonald Islands</option>
<option value="Honduras">Honduras</option>
<option value="Hong Kong S.A.R.">Hong Kong S.A.R.</option>
<option value="Hungary">Hungary</option>
<option value="Iceland">Iceland</option>
<option value="India">India</option>
<option value="Indonesia">Indonesia</option>
<option value="Iran">Iran</option>
<option value="Iraq">Iraq</option>
<option value="Ireland">Ireland</option>
<option value="Israel">Israel</option>
<option value="Italy">Italy</option>
<option value="Jamaica">Jamaica</option>
<option value="Japan">Japan</option>
<option value="Jersey">Jersey</option>
<option value="Jordan">Jordan</option>
<option value="Kazakhstan">Kazakhstan</option>
<option value="Kenya">Kenya</option>
<option value="Kiribati">Kiribati</option>
<option value="Korea North">Korea North</option>
<option value="Korea South">Korea South</option>
<option value="Kuwait">Kuwait</option>
<option value="Kyrgyzstan">Kyrgyzstan</option>
<option value="Laos">Laos</option>
<option value="Latvia">Latvia</option>
<option value="Lebanon">Lebanon</option>
<option value="Lesotho">Lesotho</option>
<option value="Liberia">Liberia</option>
<option value="Libya">Libya</option>
<option value="Liechtenstein">Liechtenstein</option>
<option value="Lithuania">Lithuania</option>
<option value="Luxembourg">Luxembourg</option>
<option value="Macau S.A.R.">Macau S.A.R.</option>
<option value="Macedonia">Macedonia</option>
<option value="Madagascar">Madagascar</option>
<option value="Malawi">Malawi</option>
<option value="Malaysia">Malaysia</option>
<option value="Maldives">Maldives</option>
<option value="Mali">Mali</option>
<option value="Malta">Malta</option>
<option value="Man (Isle of)">Man (Isle of)</option>
<option value="Marshall Islands">Marshall Islands</option>
<option value="Martinique">Martinique</option>
<option value="Mauritania">Mauritania</option>
<option value="Mauritius">Mauritius</option>
<option value="Mayotte">Mayotte</option>
<option value="Mexico">Mexico</option>
<option value="Micronesia">Micronesia</option>
<option value="Moldova">Moldova</option>
<option value="Monaco">Monaco</option>
<option value="Mongolia">Mongolia</option>
<option value="Montserrat">Montserrat</option>
<option value="Morocco">Morocco</option>
<option value="Mozambique">Mozambique</option>
<option value="Myanmar">Myanmar</option>
<option value="Namibia">Namibia</option>
<option value="Nauru">Nauru</option>
<option value="Nepal">Nepal</option>
<option value="Netherlands Antilles">Netherlands Antilles</option>
<option value="Netherlands The">Netherlands The</option>
<option value="New Caledonia">New Caledonia</option>
<option value="New Zealand">New Zealand</option>
<option value="Nicaragua">Nicaragua</option>
<option value="Niger">Niger</option>
<option value="Nigeria">Nigeria</option>
<option value="Niue">Niue</option>
<option value="Norfolk Island">Norfolk Island</option>
<option value="Northern Mariana Islands">Northern Mariana Islands</option>
<option value="Norway">Norway</option>
<option value="Oman">Oman</option>
<option value="Pakistan">Pakistan</option>
<option value="Palau">Palau</option>
<option value="Palestinian Territory Occupied">Palestinian Territory Occupied</option>
<option value="Panama">Panama</option>
<option value="Papua new Guinea">Papua new Guinea</option>
<option value="Paraguay">Paraguay</option>
<option value="Peru">Peru</option>
<option value="Philippines">Philippines</option>
<option value="Pitcairn Island">Pitcairn Island</option>
<option value="Poland">Poland</option>
<option value="Portugal">Portugal</option>
<option value="Puerto Rico">Puerto Rico</option>
<option value="Qatar">Qatar</option>
<option value="Reunion">Reunion</option>
<option value="Romania">Romania</option>
<option value="Russia">Russia</option>
<option value="Rwanda">Rwanda</option>
<option value="Saint Helena">Saint Helena</option>
<option value="Saint Kitts And Nevis">Saint Kitts And Nevis</option>
<option value="Saint Lucia">Saint Lucia</option>
<option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
<option value="Saint Vincent And The Grenadines">Saint Vincent And The Grenadines</option>
<option value="Samoa">Samoa</option>
<option value="San Marino">San Marino</option>
<option value="Sao Tome and Principe">Sao Tome and Principe</option>
<option value="Saudi Arabia">Saudi Arabia</option>
<option value="Senegal">Senegal</option>
<option value="Serbia">Serbia</option>
<option value="Seychelles">Seychelles</option>
<option value="Sierra Leone">Sierra Leone</option>
<option value="Singapore">Singapore</option>
<option value="Slovakia">Slovakia</option>
<option value="Slovenia">Slovenia</option>
<option value="Smaller Territories of the UK">Smaller Territories of the UK</option>
<option value="Solomon Islands">Solomon Islands</option>
<option value="Somalia">Somalia</option>
<option value="South Africa">South Africa</option>
<option value="South Georgia">South Georgia</option>
<option value="South Sudan">South Sudan</option>
<option value="Spain">Spain</option>
<option value="Sri Lanka">Sri Lanka</option>
<option value="Sudan">Sudan</option>
<option value="Suriname">Suriname</option>
<option value="Svalbard And Jan Mayen Islands">Svalbard And Jan Mayen Islands</option>
<option value="Swaziland">Swaziland</option>
<option value="Sweden">Sweden</option>
<option value="Switzerland">Switzerland</option>
<option value="Syria">Syria</option>
<option value="Taiwan">Taiwan</option>
<option value="Tajikistan">Tajikistan</option>
<option value="Tanzania">Tanzania</option>
<option value="Thailand">Thailand</option>
<option value="Togo">Togo</option>
<option value="Tokelau">Tokelau</option>
<option value="Tonga">Tonga</option>
<option value="Trinidad And Tobago">Trinidad And Tobago</option>
<option value="Tunisia">Tunisia</option>
<option value="Turkey">Turkey</option>
<option value="Turkmenistan">Turkmenistan</option>
<option value="Turks And Caicos Islands">Turks And Caicos Islands</option>
<option value="Tuvalu">Tuvalu</option>
<option value="Uganda">Uganda</option>
<option value="Ukraine">Ukraine</option>
<option value="United Arab Emirates">United Arab Emirates</option>
<option value="United Kingdom">United Kingdom</option>
<option value="United States">United States</option>
<option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
<option value="Uruguay">Uruguay</option>
<option value="Uzbekistan">Uzbekistan</option>
<option value="Vanuatu">Vanuatu</option>
<option value="Vatican City State (Holy See)">Vatican City State (Holy See)</option>
<option value="Venezuela">Venezuela</option>
<option value="Vietnam">Vietnam</option>
<option value="Virgin Islands (British)">Virgin Islands (British)</option>
<option value="Virgin Islands (US)">Virgin Islands (US)</option>
<option value="Wallis And Futuna Islands">Wallis And Futuna Islands</option>
<option value="Western Sahara">Western Sahara</option>
<option value="Yemen">Yemen</option>
<option value="Yugoslavia">Yugoslavia</option>
<option value="Zambia">Zambia</option>
<option value="Zimbabwe">Zimbabwe</option>
</select>
                                            <p class="help-block"></p>
                                        </div>
                                    </div>
                                    <div class="control-group form-group">
                                        <div class="controls">
                                            <textarea rows="5" cols="100" placeholder="Message" class="form-control" name="message" id="message" required data-validation-required-message="Please enter your message" maxLength="999" onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div id="success"></div>
                                    {/* <button className='btn btn-success'
          onClick={this.createNotification('success')}>Success
        </button> */}
                                    <button type="submit" class="btn btn-primary" id="sendMessageButton">Send Message</button>
                                </form>
                            </div>
                            <div class="col-lg-4 mb-4 contact-right">
                                <h3>Let's Connect</h3>
                                <div className="row pb-25">
                                    <div className="col-md-12">
                                        <h5>{CONSTANTS.PROJECT_TITLE}</h5>
                                    </div>
                                    <div className="col-md-1" style={{width : 'auto',}}>
                                        <i class="fas fa-map-marker-alt" style={{fontSize : "1.5em",color: "#007bff",marginRight : "25px",marginTop : "55px"}}></i> 
                                    </div>
                                    <div className="col-md-11" style={{width : 'auto',}}>
                                        <p className="mb-15 ml-2">
                                            
                                            <br></br>Prabhu-Greens, 07
                                            <br></br>Vidhate Nagar, Sri Ravi Shankar Marg
                                            {/* <br></br>Behind Inox Theater, Wadala Shiwar 
                                            <br></br>Nashik Road, Nashik */}
                                            <br></br>Nashik - 422006
                                            <br></br>Maharashtra, INDIA
                                            <br/><br/>
                                        </p>
                                    </div>
                                </div>
                                <div className="row pb-25">
                                    <div className="col-md-1" style={{width : 'auto',}}>
                                        <i class="fas fa-user" style={{fontSize : "1.5em",color: "#007bff",marginRight : "25px",marginTop : "5px"}}></i> 
                                    </div>
                                    <div className="col-md-11" style={{width : 'auto',}}>
                                        <span style={{paddingBottom: "15px"}}  className="ml-2">
                                        <b>{CONSTANTS.OWNER_NAME}</b> - {CONSTANTS.OWNER_DESIGNATION}
                                        </span>
                                        {/*<br/>
                                        <span className="ml-5">{CONSTANTS.PUBLIC_CONTACT_NUMBER}</span>*/}
                                    </div>
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-md-1" style={{width : 'auto',}}>
                                        <i class="fas fa-envelope" style={{fontSize : "1.5em",color: "#007bff",marginRight : "15px",marginTop : "15px"}}></i> 
                                    </div>
                                    <div className="col-md-10" style={{width : '65%',}}>
                                        <span className="pb-15">
                                            <a href={'mailto:'+CONSTANTS.PUBLIC_CONTACT_EMAIL_2}  className="ml-3"> {CONSTANTS.PUBLIC_CONTACT_EMAIL_2} </a>
                                            <a href={'mailto:'+CONSTANTS.PUBLIC_CONTACT_EMAIL} className="ml-3"> {CONSTANTS.PUBLIC_CONTACT_EMAIL} </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer/>
            </div>
        )
    }
}

contact_us.propTypes = {

}

export default contact_us
