import React from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";

import Top from '../common/top';
import Header from '../common/header';
import Footer from '../common/footer';
import Customers from '../home/customers';
import Contact from '../home/contact_us';

import * as CONSTANTS from '../../Constants';

const csv = props => {
    return (
      <div>
        <Top/>
        <Header/>
        <div class="full-title">
          <div class="container">
            <h1 class="mt-4 mb-3">Services - Computer System Validation (CSV)</h1>
            <div class="breadcrumb-main">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/" href="index.html">Home</Link>
                </li>
                <li class="breadcrumb-item active"> Services </li>
                <li class="breadcrumb-item active"> CSV </li>
              </ol>
            </div>
          </div>
        </div>

          <div class="container">
          <div class="about-main">
            <div class="row">
              <div class="col-lg-6">
                <img class="img-fluid rounded mb-4" src="assets/images/services-img-04.jpg" alt="" />
              </div>
              <div class="col-lg-6">
                <h3 className="mb-3">Computer System Validation (CSV)</h3>
                <ul>
                    <li>Validation activities and Deliverables for new system</li>
                    <li>
                      <ul>
                        <li>Supplier/Vendor Audit</li>
                        <li>User Requirement Specification</li>
                        <li>System Risk Assessment</li>      
                        <li>Validation Planning</li>
                        <li>Validation Protocols (DQ,IQ, OQ and PQ)</li>
                        <li>Test Plans & Reports</li>
                        <li>Execution of Test Procedures</li>
                        <li>ER/ES Compliance Assessment</li>
                        <li>Traceble Matrix</li>
                        <li>Validation Summary Report(VSR)</li>
                      </ul>
                    </li>
                    <li>Validation activities & Deliverables for Existing(Legacy) System</li>
                    <li>
                      <ul>
                        <li>Gap Analysis for Computerized System</li>
                        <li>GxP and ER/ES impact Assessment</li>
                        <li>System Risk Assessment & Classification</li>      
                        <li>Remediation Plan Preparation and Approval</li>
                        <li>Remediation Implementaion</li>
                        <li>Revalidation or Retrospective Validation</li>
                        <li>ER/ES Compliance Verification</li>
                        <li>Remediation Report</li>
                      </ul>
                    </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <Customers />
        
        <Contact />
        <Footer/>
      </div>
    )
}

csv.propTypes = {

}

export default csv
