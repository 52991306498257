import React from 'react'
import PropTypes from 'prop-types'

const services = props => {
    return (
      <div class="services-bar">
          <div class="container">
            <h1 class="py-4 mb-4">Our Best Services </h1>
            <div class="row">
              <div class="col-lg-4 mb-4">
                <div class="card h-100">
                  <div class="card-img">
                    <img class="img-fluid" src="assets/images/services-img-01.jpg" alt="" style={{ height: "175px", }} />
                  </div>
                  <div class="card-body">
                    <h4 class="card-header"> Third Party Quality Audit (TPQA) </h4>
                    <p class="card-text">The Third Party Quality Audits (TPQA) are carried out at this site to check online GMP compliance systems as per current regulatory requirement as well as to verify all the systems are in well state of GMP requirements. Audits conducted by Certified Auditors with Risk/Practical/Logical Based Approach as well as per current regulatory guidelines.</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-4">
                <div class="card h-100">
                  <div class="card-img">
                    <img class="img-fluid" src="assets/images/services-img-02.jpg" alt="" style={{ height: "175px", }} />
                  </div>
                  <div class="card-body">
                    <h4 class="card-header"> GMP Supports </h4>
                    <p class="card-text">We are providing the GMP supports to Pharmaceutical industries with respect to system preparation/implementation/upgradation/GAP analysis/site preparations for inspections through continual improvement programmes so that site becomes GMP Complaint for 24x7</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-4">
                <div class="card h-100">
                  <div class="card-img">
                    <img class="img-fluid" src="assets/images/services-img-03.jpg" alt="" style={{ height: "175px", }} />
                  </div>
                  <div class="card-body">
                    <h4 class="card-header"> Training </h4>
                    <p class="card-text">VANSHI PHARMA-TECH offering trainings related to different areas by experienced trainers as customized trainings to make the employee well aware about current regulatory requirements and to build up their confidence during performing their GMP activities.</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-4">
                <div class="card h-100">
                  <div class="card-img">
                    <img class="img-fluid" src="assets/images/services-img-04.jpg" alt="" style={{ height: "175px", }}  />
                  </div>
                  <div class="card-body">
                    <h4 class="card-header"> Computer System Validation (CSV) </h4>
                    <p class="card-text">Each Computerized System will be assessed for its GxP impact. Electronic Records 
(ER) and Electronic signatures (ES) impact on the organization, followed by a Risk Assessment as per applicable procedures as per GAMP-5.
We will be providing the documented evidence that the system will repeatedly and reliably do what it is designed to do, is "fit-for-purpose", and complies with the applicable rules and regulations.
</p>
                  </div>						
                </div>
              </div>
              
            </div>
          </div>
        </div>
    )
}

services.propTypes = {

}

export default services
