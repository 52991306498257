import React from 'react'
import PropTypes from 'prop-types'

import {Link} from "react-router-dom";

import * as CONSTANTS from '../../Constants';

const contact_us = props => {
    return (
      <div class="touch-line">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <p>{CONSTANTS.PROJECT_TITLE} have a great experienced team to provide and fulfill all the regulatory needs in all aspects.</p>
            </div>
            <div class="col-md-4">
              <Link className={'btn btn-lg btn-secondary btn-block '} to="/contact_us" style={{fontWeight : 600}}>Contact Us </Link>
            </div>
          </div>
        </div>
      </div>
    )
}

contact_us.propTypes = {

}

export default contact_us
