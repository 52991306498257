import logo from './logo.svg';

import { BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams } from "react-router-dom"
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

import Home from './components/pages/home';
import About from './components/pages/about';
import Services from './components/pages/services';
import Marketing from './components/pages/marketing';
import Consultation from './components/pages/consultation';
//import Training from './components/pages/training';
import Gallery from './components/pages/gallery';
import News from './components/pages/news';
import Contact_us from './components/pages/contact_us';


import TPQA from './components/services/tpqa';
import GMP from './components/services/gmp';
import Training from './components/services/training';
import CSV from './components/services/csv';

import ScrollToTop from './components/common/ScrollToTop';

function App() {
  return (
    <div className="App">
        <div class="wrapper-main">
          <Router>
            <ScrollToTop />
            <Switch>
              <Route path="/" component={Home} exact />
              <Route path="/about" component={About} />
              <Route path="/services" component={Services} />
              <Route path="/marketing" component={Marketing} />
              <Route path="/consultation" component={Consultation} />
              <Route path="/gallery" component={Gallery} />
              <Route path="/news" component={News} />
              <Route path="/contact_us" component={Contact_us} />

              <Route path="/tpqa" component={TPQA} />
              <Route path="/gmp" component={GMP} />
              <Route path="/training" component={Training} />
              <Route path="/csv" component={CSV} />
              <Route component={Error} />
            </Switch>
            <NotificationContainer/>
          </Router>
        </div>
    </div>
  );
}

export default App;
