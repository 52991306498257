import React from 'react'
import PropTypes from 'prop-types'

import * as CONSTANTS from '../../Constants';

import {Link} from "react-router-dom";

const footer = props => {
    return (
        <footer class="footer">
            <div class="container bottom_border">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-6 col">
                        <h5 class="headin5_amrc col_white_amrc pt2">About Us</h5>
                        <p class="mb10">{CONSTANTS.PROJECT_TITLE} is an innovative and advanced consulting organization providing exceptional quality and regulatory compliance services globally.</p>
                        {/* <ul class="footer-social">
                            <li><a class="facebook hb-xs-margin" href="#"><span class="hb hb-xs spin hb-facebook"><i class="fab fa-facebook-f"></i></span></a></li>
                            <li><a class="twitter hb-xs-margin" href="#"><span class="hb hb-xs spin hb-twitter"><i class="fab fa-twitter"></i></span></a></li>
                            <li><a class="instagram hb-xs-margin" href="#"><span class="hb hb-xs spin hb-instagram"><i class="fab fa-instagram"></i></span></a></li>
                            <li><a class="googleplus hb-xs-margin" href="#"><span class="hb hb-xs spin hb-google-plus"><i class="fab fa-google-plus-g"></i></span></a></li>
                            <li><a class="dribbble hb-xs-margin" href="#"><span class="hb hb-xs spin hb-dribbble"><i class="fab fa-dribbble"></i></span></a></li>
                        </ul> */}
                    </div>	
                    <div class="col-lg-2 col-md-6 col-sm-6">
                        <h5 class="headin5_amrc col_white_amrc pt2">Quick links</h5>
                        <ul class="footer_ul_amrc">
                            <li><Link to="/"><i class="fas fa-long-arrow-alt-right"></i>Home </Link></li>
                            <li><Link to="/about"><i class="fas fa-long-arrow-alt-right"></i>About Us</Link></li>
                            {/* <li><Link to="/Services"><i class="fas fa-long-arrow-alt-right"></i>Services</Link></li> */}
                            <li><Link to="/contact_us"><i class="fas fa-long-arrow-alt-right"></i>Contact Us</Link></li>
                        </ul>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 col">
                        <h5 class="headin5_amrc col_white_amrc pt2">Address</h5>
                        <p>
                            <h6>{CONSTANTS.PROJECT_TITLE}</h6>
                            Prabhu-Greens, 07
                            <br></br>Vidhate Nagar, Sri Ravi Shankar Marg
                            <br></br>Nashik - 422006
                            <br></br>Maharashtra, INDIA
                        </p>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 ">
                        <div class="news-box">
                            <h5 class="headin5_amrc col_white_amrc pt2">Connect Us</h5>
                            <div className="row">
                                <div className="col-md-2 mt-1" style={{width : 'auto',}}>
                                    <i class="fas fa-user" style={{fontSize : "1em",color: "white",marginRight : "25px"}}></i>
                                </div> 
                                <div className="col-md-10" style={{width : 'auto',}}>
                                    <center>
                                    {/*<span style={{paddingBottom: "15px",color: "white"}}>
                                    {CONSTANTS.PUBLIC_CONTACT_NUMBER}
                                    </span>
                    <br/>*/}
                                    <small style={{paddingBottom: "15px",color: "white"}}>
                                       <b style={{textTransform: "uppercase"}}>{CONSTANTS.OWNER_NAME}</b> - {CONSTANTS.OWNER_DESIGNATION}
                                    </small>
                                    </center>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-md-2" style={{width : 'auto',}}>
                                    <i class="fas fa-envelope" style={{fontSize : "1em",color: "white",marginRight : "15px",marginTop : "15px"}}></i>
                                </div>
                                <div className="col-md-10" style={{width : '65%',}}>
                                    <span style={{paddingBottom: "15px"}}>
                                    <a href={'mailto:'+CONSTANTS.PUBLIC_CONTACT_EMAIL_2}  style={{color: "white"}}> {CONSTANTS.PUBLIC_CONTACT_EMAIL_2} </a>
                                    <a href={'mailto:'+CONSTANTS.PUBLIC_CONTACT_EMAIL}  style={{color: "white"}}> {CONSTANTS.PUBLIC_CONTACT_EMAIL} </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div className="col-md-6">
                        <p className="copyright">Copyright &copy;{(new Date().getFullYear())} All rights reserved.</p>
                    </div>
                    <div className="col-md-6">
                        <p className="copyright text-right">
                            <a target="_blank" style={{color: "#00c2e5", textDecoration:"none"}} href="https://www.webneel.in/">WebNeel Solutions</a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

footer.propTypes = {

}

export default footer
