import React from 'react'
import PropTypes from 'prop-types'

import * as CONSTANTS from '../../Constants';

const advantages = props => {
    return (
      <div id="about" class="basic-4">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="col-lg-12">
                                <h2 class="py-4">Why Only {CONSTANTS.PROJECT_TITLE} ?...</h2>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            
                            <div class="team-member">
                                <div class="image-wrapper border-orange">
                                    <img class="img-fluid" src="assets/images/target.svg" alt="alternative" style={{maxWidth:"50%"}}/>
                                </div>
                                <p class="p-large"><strong>Precised Quality</strong></p>
                            </div>
                            <div class="team-member">
                                <div class="image-wrapper border-yellow">
                                    <img class="img-fluid" src="assets/images/best-price.svg" alt="alternative" style={{maxWidth:"50%"}}/>
                                </div>
                                <p class="p-large"><strong>Affordability</strong></p>
                            </div>
                            <div class="team-member">
                                <div class="image-wrapper border-green">
                                    <img class="img-fluid" src="assets/images/award.svg" alt="alternative" style={{maxWidth:"50%"}}/>
                                </div>
                                <p class="p-large"><strong>Trustability</strong></p>
                            </div>
                            <div class="team-member">
                                <div class="image-wrapper border-red">
                                    <img class="img-fluid" src="assets/images/happy-face.svg" alt="alternative" style={{maxWidth:"50%"}}/>
                                </div>
                                <p class="p-large"><strong>Customer Satisfaction</strong></p>
                            </div>
                            <div class="team-member">
                                <div class="image-wrapper border-blue">
                                    <img class="img-fluid" src="assets/images/qualify.svg" alt="alternative" style={{maxWidth:"50%"}}/>
                                </div>
                                <p class="p-large"><strong>Qualified Auditors</strong></p>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
    )
}

advantages.propTypes = {

}

export default advantages
