export const PROJECT_TITLE = 'VANSHI PHARMA-TECH Consultants';
export const OWNER_NAME = 'Pradip Kale';
export const OWNER_DESIGNATION = 'APIC Certified Auditor';
export const PUBLIC_CONTACT_NUMBER = '+91 9422752908';
export const PUBLIC_CONTACT_EMAIL = 'contact@vanshipharmatech.com';
export const PUBLIC_CONTACT_EMAIL_2 = 'pradip@vanshipharmatech.com';
export const SERVER_BASE_URL = 'http://www.vanshipharmatech.com/';
export const PROFILE_DIR_PATH = '/uploads/profile_image/';
export const PLAN_DIR_PATH = '/uploads/plan_image/';
export const STORY_DIR_PATH = '/uploads/happy_story_image/';
export const GALLERY_DIR_PATH = '/uploads/gallery_image/';
export const DEFAULT_PLAN_IMAGE = 'default_image.jpg';
export const DEFAULT_PROFILE_IMAGE = 'default.jpg';