import React from 'react'
import PropTypes from 'prop-types'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const customers = props => {
    return (
      <div class="customers-box"> 
		     <div class="container">
            <div style={{ margin: '15px', position: 'relative' }}>

              <h2 className="p-b-25">Our Customers</h2>
              <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={1000}
                  keyBoardControl={true}
                  customTransition="transform 300ms ease-in-out"
                  transitionDuration={1000}
                  containerClass="carousel-container margin-15"
                  removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                  responsive={{
                      desktop: {
                        breakpoint: {
                          max: 3000,
                          min: 1024
                        },
                        items: 5,
                        partialVisibilityGutter: 40
                      },
                      mobile: {
                        breakpoint: {
                          max: 464,
                          min: 0
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                      },
                      tablet: {
                        breakpoint: {
                          max: 1024,
                          min: 464
                        },
                        items: 3,
                        partialVisibilityGutter: 30
                      }
                    }}
                  >
                  <img
                    src="/assets/images/logo.png"
                    style={{
                      display: 'block',
                      height: '80%',
                      margin: 'auto',
                      padding: '5px',
                      width: '80%'
                    }}
                  />
                  <img
                    src="/assets/images/logo (1).png"
                    style={{
                      display: 'block',
                      height: '80%',
                      margin: 'auto',
                      padding: '5px',
                      width: '80%'
                    }}
                  />
                  <img
                    src="/assets/images/logo (2).png"
                    style={{
                      display: 'block',
                      height: '80%',
                      margin: 'auto',
                      padding: '5px',
                      width: '80%'
                    }}
                  />
                  <img
                    src="/assets/images/logo (3).png"
                    style={{
                      display: 'block',
                      height: '80%',
                      margin: 'auto',
                      padding: '5px',
                      width: '80%'
                    }}
                  />
                  {/* <img
                    src="/assets/images/logo (4).png"
                    style={{
                      display: 'block',
                      height: '80%',
                      margin: 'auto',
                      padding: '5px',
                      width: '80%'
                    }}
                  /> */}
                  <img
                    src="/assets/images/bio.gif"
                    style={{
                      display: 'block',
                      height: '80%',
                      margin: 'auto',
                      padding: '5px',
                      width: '80%'
                    }}
                  />
              </Carousel>
            </div>
          </div>
        </div>
    )
}

customers.propTypes = {

}

export default customers
