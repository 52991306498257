import React from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";

import Top from '../common/top';
import Header from '../common/header';
import Footer from '../common/footer';
import Customers from '../home/customers';
import Contact from '../home/contact_us';

import * as CONSTANTS from '../../Constants';

const tpqa = props => {
    return (
      <div>
        <Top/>
        <Header/>
        <div class="full-title">
          <div class="container">
            <h1 class="mt-4 mb-3">Services - Third Party Quality Audit (TPQA)</h1>
            <div class="breadcrumb-main">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/" href="index.html">Home</Link>
                </li>
                <li class="breadcrumb-item active"> Services </li>
                <li class="breadcrumb-item active"> TPQA </li>
              </ol>
            </div>
          </div>
        </div>

          <div class="container">
          <div class="about-main">
            <div class="row">
              <div class="col-lg-6">
                <img class="img-fluid rounded mb-4" src="assets/images/services-img-01.jpg" alt="" />
              </div>
              <div class="col-lg-6">
                <h3 className="mb-3">Third Party Quality Audit (TPQA)</h3>
                <ul>
                    <li>To Conduct a TPQA at</li>
                    <li>
                      <ul>
                        <li>API manufacturers</li>
                        <li>
                          <ul>
                            <li>General APIs</li>
                            <li>Anti-Cancer (Oncology)</li>
                            <li>Harmones</li>
                            <li>Steroids</li>
                            <li>Others</li>
                          </ul>
                        </li>
                        <li>Formulation Manufacturers</li>
                        <li>
                          <ul>
                            <li>Injectables (SVP/LVP)</li>
                            <li>Vaccines</li>
                            <li>Solid Oral Dosages</li>
                            <li>Inhalers</li>
                            <li>Opthalmic Preparations</li>
                            <li>Pellets</li>
                            <li>Others</li>
                          </ul>
                        </li>
                        <li>Herbal Products</li>
                        <li>Packaging Materials ( Primary / Secondary / Tertiary )</li>
                        {/* <li>
                          <ul>
                            <li>Primary PM</li>
                            <li>
                              <ul>
                                <li>Ampals</li>
                                <li>Glass Variations</li>
                                <li>Polyhydrate LDPE Bags</li>
                                <li>Alu-Alu Foils</li>
                                <li>PVC-PVDC Foils</li> 
                                <li>Containers</li>
                                <li>Canishers/Actuals</li>
                                <li>Others</li>
                              </ul>
                            </li>
                          </ul>
                        </li> */}
                        <li>Customer's Complaint Investigation Audits</li>
                        <li>Facility Qualification Audits w.r.t. Plant Design</li>
                        <li>Medical Device Audits</li>
                      </ul>
                    </li>
                  </ul>
              </div>
            </div>
          </div>
        </div>

        <Customers />
        
        <Contact />
        <Footer/>
      </div>
    )
}

tpqa.propTypes = {

}

export default tpqa
