import React from 'react'
import PropTypes from 'prop-types'

import Top from '../../components/common/top';
import Header from '../../components/common/header';
import Slider from '../../components/common/slider';
import Footer from '../../components/common/footer';

import Welcome from '../../components/home/welcome';
import Services from '../../components/home/services';
import Portfolio from '../../components/home/portfolio';
import Blog from '../../components/home/blog';
import Contact from '../../components/home/contact_us';
import Customers from '../../components/home/customers';
import Advantages from '../../components/home/advantages';
import Testimonials from '../../components/home/testimonials';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

const home = props => {
    return (
        <div>
            <Top/>
            <Header/>
            <Slider/>

            <Welcome />
            <Advantages />
            <Services />
            {/* <Portfolio />
            <Blog /> */}
            {/* <section class="ftco-section testimony-section slash">
                <div class="container">
                    <div class="row justify-content-center mb-5 pb-3">
                    <div class="col-md-7 text-center heading-section heading-section-white ftco-animate">
                        <h1 class="py-4">Happy Customers</h1>
                    </div>
                    </div>
                    <div class="row ftco-animate justify-content-center">
                    <div class="col-md-12">
                        <div class="carousel-testimony owl-carousel ftco-owl">
                        <Carousel responsive={responsive}>
  <div>Item 1</div>
  <div>Item 2</div>
  <div>Item 3</div>
  <div>Item 4</div>
</Carousel>
                        </div>
                    </div>
                    </div>
                </div>
            </section> */}
            <Testimonials />
            <Customers />
            <Contact />
            
            <Footer/>
        </div>
    )
}

home.propTypes = {

}

export default home
