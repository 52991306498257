import React from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";

import Top from '../../components/common/top';
import Header from '../../components/common/header';
import Footer from '../../components/common/footer';

import * as CONSTANTS from '../../Constants';

const consultation = props => {
    return (
        <div>
            <Top/>
            <Header/>
            <div class="full-title">
                <div class="container">
                    <h1 class="mt-4 mb-3"> Consultation</h1>
                    <div class="breadcrumb-main">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                        <Link to="/" href="index.html">Home</Link>
                        </li>
                        <li class="breadcrumb-item active"> Consultation </li>
                    </ol>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="about-main">
                    <div class="row">
                    <div class="col-lg-6">
                        <img class="img-fluid rounded mb-4" src="assets/images/about-img.jpg" alt="" />
                    </div>
                    <div class="col-lg-6">
                        <h2>Consultation</h2>
                        <p>With the growing global trend in pharma business to outsource some of the business functions and research tools in the different phases of spanning the entrance or growing new markets and new products, {CONSTANTS.PROJECT_TITLE} offers consultation in the following.</p>
                        <li>Marketing Strategies</li>
                        <li>Best model for new market/country</li>
                        <li>Research for new client/partners</li>
                        <li>Pricing and competition</li>
                        <li>Business Development</li>
                        <li>Product portfolio management</li>
                        <li>Market entry consultation</li>
                        <li>Feasibility studies</li>
                        <li>Regulatory consultation</li>
                        <li>Pharmacovigllance System</li>
                    </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    )
}

consultation.propTypes = {

}

export default consultation
