import React from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";

import Top from '../../components/common/top';
import Header from '../../components/common/header';
import Footer from '../../components/common/footer';

import * as CONSTANTS from '../../Constants';

const marketing = props => {
    return (
        <div>
            <Top/>
            <Header/>
            <div class="full-title">
                <div class="container">
                    <h1 class="mt-4 mb-3"> Marketing</h1>
                    <div class="breadcrumb-main">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                        <Link to="/" href="index.html">Home</Link>
                        </li>
                        <li class="breadcrumb-item active"> Marketing </li>
                    </ol>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="about-main">
                    <div class="row">
                    <div class="col-lg-6">
                        <img class="img-fluid rounded mb-4" src="assets/images/about-img.jpg" alt="" />
                    </div>
                    <div class="col-lg-6">
                        <h2>Marketing</h2>
                        <p>{CONSTANTS.PROJECT_TITLE} team and extensive network in the Middle East and North Africa provides marketing solutions for pharma Business through :</p>
                        <li>Direct distribution and marketing through Pharma Solutions own affiliates in some countries.</li>
                        <li>Locate and assign agents / distributors in the Middle East and North Africa region through contracts</li>
                        <li>Facilitate toll manufacturing, secondary packing and licensing agreements with local manufacturers in the Middle East and North Africa region</li>
                        <li>Handle marketing functions for launched or (soon-to-be-launched) products</li>
                    </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    )
}

marketing.propTypes = {

}

export default marketing
