import React from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";

import Top from '../../components/common/top';
import Header from '../../components/common/header';
import Footer from '../../components/common/footer';
import Customers from '../../components/home/customers';
import Testimonials from '../../components/home/testimonials';
import Contact from '../../components/home/contact_us';

import * as CONSTANTS from '../../Constants';

const about = props => {
    return (
      <div>
        <Top/>
        <Header/>
        <div class="full-title">
          <div class="container">
            <h1 class="mt-4 mb-3"> About Us</h1>
            <div class="breadcrumb-main">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/" href="index.html">Home</Link>
                </li>
                <li class="breadcrumb-item active"> About Us </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="about-main">
            <div class="row">
              {/* <div class="col-lg-4">
                <img class="img-fluid rounded mt-5 pt-5" src="assets/images/discussion.jpeg" alt="" />
              </div> */}
              <div class="col-lg-12">
                <h2>About Us</h2>
                <div className="" style={{textTransform: 'none', backgroundColor: '#dceaff', borderStyle: "solid", borderWidth : '1px', borderColor : '#207DFF', padding: '15px'}}>

                <p>
                {CONSTANTS.PROJECT_TITLE} is an innovative and advanced consulting organization providing exceptional quality and regulatory compliance services globally. {CONSTANTS.PROJECT_TITLE} GMP Consultancy associates with you for services related to GMP Audits, Quality Assurance, Compliance, CSV, Procedure/System Optimization & Development and Training. {CONSTANTS.PROJECT_TITLE} Consultancy is a GMP consulting organization intended to support in various current regulatory requirements, perform quality audits to access the gaps, suggest solutions to meet the regulatory requirements and develop the quality systems.</p>

                <p>We have a great experienced team to provide and fulfill all the regulatory needs in all aspects. Our Approach is to emphasize the development of systems and procedures in concurrence with the prevalent working practices and at the same time guiding the business partner to implement policies and procedures in accordance with applicable International Management System standards.</p>

                <p>A multi-disciplinary team of qualified regulatory affairs consultants is at your disposal for all aspects of your marketing authorization applications for active substances and human medicinal products. {CONSTANTS.PROJECT_TITLE} shall provide full regulatory support over the whole life cycle of your product.</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="about-inner">
          <div class="container">
            <div class="row mb-4">
              <div class="col-lg-6">
                <div class="left-ab">
                  <h3>Our Mission</h3>
                  <p>To provide qualitative consultancy services in pharmaceutical industries by constant novelties, commitment & trust...</p>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="right-ab">
                  <img class="img-fluid rounded mb-4 mr-5" src="/assets/images/mission.jpg" alt=""  width="350px"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="right-ab">
                  <img class="img-fluid rounded mb-4 ml-5" src="/assets/images/vision1.jpg" alt="" width="350px"/>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="left-ab">
                  <h3>Our Vision</h3>
                  <p>To emerge as a India's leading global pharmaceutical consultancy company which creates value for all customers by offering excellence in quality, services, commitments & customer satisfaction.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <Testimonials />
        <Customers />
        <Contact />
        <Footer/>
      </div>
    )
}

about.propTypes = {

}

export default about
