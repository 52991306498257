import React from 'react'
import PropTypes from 'prop-types'

import * as CONSTANTS from '../../Constants';

const top = props => {
    return (
        <header class="slider-main">
            <div id="carouselExampleIndicators" class="carousel slide carousel-fade" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    {/* <li data-target="#carouselExampleIndicators" data-slide-to="2"></li> */}
                </ol>
                <div class="carousel-inner" role="listbox">
                    <div class="carousel-item active" style={{backgroundImage: "url(" + "/assets/images/slider-01.png" + ")"}}>
                        <div class="carousel-caption d-md-block">
                            <h3>Welcome to <br></br>{CONSTANTS.PROJECT_TITLE}</h3>
                            <p className="m-t-25">Platform for complete Pharma Consultancy Solutions</p>
                        </div>
                    </div>
                    <div class="carousel-item" style={{backgroundImage: "url(" + "/assets/images/slider-33.jpg" + ")"}}>
                        <div class="carousel-caption d-md-block">
                            <h3>Professional Services</h3>
                            <p className="m-t-25">We are specialized in Quality Audits & GMP Consultancy</p>
                        </div>
                    </div>
                    {/* <div class="carousel-item" style={{backgroundImage: "url(" + "/assets/images/slider-33.jpg" + ")"}}>
                        <div class="carousel-caption d-md-block">
                            <h3>Business Development</h3>
                            <p className="m-t-25">A key for All Pharmaceutical Consultancy Solutions</p>
                        </div>
                    </div> */}
                </div>
                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </header>
    )
}

top.propTypes = {

}

export default top
