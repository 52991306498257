import React from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";

import Top from '../common/top';
import Header from '../common/header';
import Footer from '../common/footer';
import Customers from '../home/customers';
import Contact from '../home/contact_us';

import * as CONSTANTS from '../../Constants';

const gmp = props => {
    return (
      <div>
        <Top/>
        <Header/>
        <div class="full-title">
          <div class="container">
            <h1 class="mt-4 mb-3">Services - GMP Support</h1>
            <div class="breadcrumb-main">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/" href="index.html">Home</Link>
                </li>
                <li class="breadcrumb-item active"> Services </li>
                <li class="breadcrumb-item active"> GMP Support </li>
              </ol>
            </div>
          </div>
        </div>

          <div class="container">
          <div class="about-main">
            <div class="row">
              <div class="col-lg-6">
                <img class="img-fluid rounded mb-4" src="assets/images/services-img-02.jpg" alt="" />
              </div>
              <div class="col-lg-6">
                <h3 className="mb-3">GMP Support</h3>
                <ul>
                    <li>To Prepare site for an Inspection (EU/USFDA/WHO/Others)</li>
                    <li>To Perform GAP Analysis followed by CAPA Implementation</li>
                    <li>QMS System Establishment & Upgradation</li>      
                    <li>Regulatory Filing  & Deficiency Responce Support</li>
                    <li>CAPA Remediation</li>
                    <li>Validation/Qualification</li>
                    <li>GMP Consultation related to Green field / Brown field projects / Plant Upgradation</li>
                    <li>New QL Lab / Micro Lab setup & implementation</li>
                  </ul>
              </div>
            </div>
          </div>
        </div>

        <Customers />
        
        <Contact />
        <Footer/>
      </div>
    )
}

gmp.propTypes = {

}

export default gmp
