import React from 'react'
import PropTypes from 'prop-types'

import * as CONSTANTS from '../../Constants';

const top = props => {
    return (
        <div class="top-bar">
            <div class="container">
              <div class="row">
                <div class="col-lg-3">
                  {/* <div class="social-media">
                    <ul>
                      <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                      <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                      <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                      <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                      <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                  </div> */}
                </div>
                <div class="col-lg-9">
                  <div class="contact-details">
                    <ul>
                      <li>{/*<i class="fas fa-phone fa-rotate-90"></i> {CONSTANTS.PUBLIC_CONTACT_NUMBER} - */}<b>{CONSTANTS.OWNER_NAME}</b> ({CONSTANTS.OWNER_DESIGNATION})</li>
                      <li className={'ml-3'}><i class="fas fa-envelope"></i> <a href={'mailto:'+CONSTANTS.PUBLIC_CONTACT_EMAIL_2}  style={{color: "white"}}> {CONSTANTS.PUBLIC_CONTACT_EMAIL_2} </a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
    )
}

top.propTypes = {

}

export default top
